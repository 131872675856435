module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-170503323-1"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/404/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"⚡ Suburled - electricistas en Barcelona provincia","description":"💡Suburled es una empresa de electricidad en Barcelona con muchos años de experiencia en instalaciones domésticas e industriales. 🚗🔌 Instaladores de cargadores de coches eléctricos. Somos instaladores autorizados. Electricistas en Barcelona y provincia. Pídenos presupuesto para tu nuevo proyecto.","short_name":"Suburled","start_url":"/","display":"standalone","icon":"./src/assets/img/favicon.png","lang":"es","background_color":"#ffffff","theme_color":"#023513","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9339489114ea17cc27504e03a7380598"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.suburled.com"},
    }]
