// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cargadores-coches-electricos-js": () => import("./../../../src/pages/cargadores-coches-electricos.js" /* webpackChunkName: "component---src-pages-cargadores-coches-electricos-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-electricistas-barcelona-js": () => import("./../../../src/pages/electricistas-barcelona.js" /* webpackChunkName: "component---src-pages-electricistas-barcelona-js" */),
  "component---src-pages-error-page-js": () => import("./../../../src/pages/error-page.js" /* webpackChunkName: "component---src-pages-error-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instalacion-aire-acondicionado-js": () => import("./../../../src/pages/instalacion-aire-acondicionado.js" /* webpackChunkName: "component---src-pages-instalacion-aire-acondicionado-js" */),
  "component---src-pages-instalaciones-electricas-industriales-js": () => import("./../../../src/pages/instalaciones-electricas-industriales.js" /* webpackChunkName: "component---src-pages-instalaciones-electricas-industriales-js" */),
  "component---src-pages-mantenimiento-y-averias-electricas-js": () => import("./../../../src/pages/mantenimiento-y-averias-electricas.js" /* webpackChunkName: "component---src-pages-mantenimiento-y-averias-electricas-js" */),
  "component---src-pages-nota-legal-js": () => import("./../../../src/pages/nota-legal.js" /* webpackChunkName: "component---src-pages-nota-legal-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-presupuesto-js": () => import("./../../../src/pages/presupuesto.js" /* webpackChunkName: "component---src-pages-presupuesto-js" */),
  "component---src-templates-charger-js": () => import("./../../../src/templates/Charger.js" /* webpackChunkName: "component---src-templates-charger-js" */)
}

